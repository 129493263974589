import { Component, OnInit } from '@angular/core';
import { I9RedirectCompleted } from './implicit-i9.actions';

export const i9TaskUrl = window.location.origin + '/tasks/i9';

@Component({
  selector: 'ot-implicit-i9',
  templateUrl: './implicit-i9.html',
  styleUrls: ['./styles.scss']
})
export class ImplicitI9Component implements OnInit {
  public ngOnInit() {
    window.scrollTo(0, 0);

    parent.postMessage(new I9RedirectCompleted(), i9TaskUrl);
  }
}
